
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import GenericMixin from '@/lib/mixins/GenericMixin';

@Component({
  components: {
    TasqHistory: () => getComponent('tasqs/TasqHistory'),
  },
})
export default class TasqWellDetails extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  HEADER_TAB = 'Data entry';

  HISTORY_TAB = 'History';

  activeTab = this.HISTORY_TAB;

  clickTab(name): void {
    this.activeTab = name;
  }



  get datalist() {
    return [
      {
        title: 'Pad Name',
        value: 'Pad Name',
      },
      {
        title: 'Route',
        value: 'Route Name',
      },
      {
        title: 'Area',
        value: 'Area Name',
      },
      {
        title: 'Team',
        value: 'Team Name',
      },
    ];
  }
}
